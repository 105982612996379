export default {
    type: 'SimpleGraph',
    location: '/business/fuo/volumes/share-fulfilled',
    filter_keys: [
        ['Filter', 'fuo-vol-1'],
        ['Store/Central', 'fuo-vol-2'],
    ],
    graph_settings: {
        colors: ['#fddb2a']
    }
}
